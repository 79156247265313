<template>
    <div class="approvalpending-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="待审批" to="/client/approval/wait" />
                <van-tab replace title="已通过" to="/client/approval/pass" />
                <van-tab replace title="未通过" to="/client/approval/notpass" />
            </van-tabs>
        </div>
        <div class="approvalpending-body" v-if="dataList && dataList.length > 0">
            <div v-for="(item, index) in dataList" class="list-item">
                <van-cell is-link>
                    <!-- 使用 title 插槽来自定义标题 -->
                    <template #title>
                        <span class="custom-title">{{ item.after_sale.order.order_sn }}</span>
                    </template>
                    <template #value>
                        <p class="itemright-text" @click="toDetail(item.id)">查看详情</p>
                    </template>
                </van-cell>
            </div>
        </div>
        <van-empty v-else description="暂无数据" />
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_GOODS_APPROVAL_LIST } from "@/api/goods";
import usePublic from "@/hooks/usePublic";

let { $router } = usePublic();

onMounted(() => {
    getDataList();
});

let tabActive = ref(1);

// 获取数据
let dataList = ref([]);

let getDataList = () => {
    GET_GOODS_APPROVAL_LIST({
        status: 1,
        limit: 10000
    }).then((res) => {
        dataList.value = res.data.data.list;
        console.log(111, res.data.data);
    });
};

// 前往详情页
let toDetail = (id) => {
    console.log(id);
    $router.push({ name: "ApprovalManagementDetail", params: { id } });
};
</script>

<style lang="scss" scoped>
.approvalpending-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .approvalpending-body {
        margin-top: 10px;
        background: #fff;
        padding: 0;

        .list-item {
            border-top: dashed 1px #eee;

            :deep(.van-cell) {
                .van-cell__title {
                    flex: 0 0 180px;
                }
            }

            .custom-title {
                font-size: 14px;
            }

            .itemright-text {
                color: #df5f0b;
            }
        }

        .list-item:nth-child(1) {
            border: none !important;
        }
    }
}
</style>
